import React from "react";
import TechCard from "./TechArtCard";
import "./css/Tech.css";

const Tech = () => {
  let techCards = [
    {
      name: "3D Printing",
      photo: "https://i.imgur.com/7mh4xq9.jpg",
      alt: "3D Printing",
      description:
        "the action or process of making a physical object from a 3D model by repeatedly laying down many thin layers of a material",
      link: "https://www.3dslash.net/index.php",
    },
    {
      name: "Coding",
      photo: "https://i.imgur.com/jTdtxUk.jpg",
      alt: "Coding",
      description: "the process or activity of writing computer programs",
      link: "https://code.org/hourofcode/overview",
    },
    {
      name: "Electronics",
      photo: "https://i.imgur.com/PDCuxPu.jpg",
      alt: "Electronics",
      description:
        "the branch of technology dealing with circuits using transistors, microchips, and the movement of electrons through conductive material",
      link: "https://www.brainpop.com/games/circuitconstructionkitdc/",
    },
    {
      name: "App Design",
      photo: "https://i.imgur.com/wdehV1Y.jpg",
      alt: "App Design",
      description:
        "the process of creating a useful application that can be downloaded by a user to a mobile device",
      link: "https://appinventor.mit.edu/",
    },
    {
      name: "Augmented Reality",
      photo: "https://i.imgur.com/PHKIpzm.jpg",
      alt: "Augmented Reality",
      description:
        "a technology that places a computer-generated image on top of a user's view of the real world",
      link: "https://mergeedu.com/cube",
    },
    {
      name: "Digital Design",
      photo: "https://i.imgur.com/DbfElu3.jpg",
      alt: "Digital Design",
      description:
        "the process of creating images and elements that will ultimately be used on a screen (apps, 3D printing software, video games, etc)",
      link: "http://tinkercad.com/",
    },
    {
      name: "Game Design",
      photo: "https://i.imgur.com/0LYADXN.jpg",
      alt: "Game Design",
      description:
        "the art of applying design and aesthetics to create a game for entertainment, educational, exercise, or experimental purposes",
      link: "https://scratch.mit.edu/ideas",
    },
    {
      name: "Laser Cutting",
      photo: "https://i.imgur.com/s1t2guZ.jpg",
      alt: "Laser Cutting",
      description:
        "a technology that uses a laser to vaporize materials, resulting in a sharp cut edge",
      link: "https://makerdesignlab.com/tutorials-tips/laser-cutting-beginners-guide/",
    },
    {
      name: "Microcontrollers",
      photo: "https://i.imgur.com/MXcgrUP.jpg",
      alt: "Microcontrollers",
      description:
        "a small computer on a single integrated circuit chip. It contains one or more CPUs with memory and programmable I/O peripherals (or add-on devices)",
      link: "https://makecode.microbit.org/#",
    },
    {
      name: "Robotics",
      photo: "https://i.imgur.com/Xs7W6GH.jpg",
      alt: "Robotics",
      description:
        "the branch of technology that deals with the design, construction, operation, and application of robots",
      link: "https://makecode.mindstorms.com/",
    },
    {
      name: "Virtual Reality",
      photo: "https://i.imgur.com/rYiiZe8.jpg",
      alt: "Virtual Reality",
      description:
        "the computer-generated simulation of a 3D image or environment that can be interacted with by a person using equipment such as a VR headset or sensored gloves",
      link: "https://www.oculus.com/vr-for-good/creators-lab/",
    },
    {
      name: "Wearable Technology",
      photo: "https://i.imgur.com/6b41BYo.jpg",
      alt: "Wearable Techonology",
      description:
        "electronic devices that can be worn as accessories, clothing, implanted in the body, or on the skin",
      link: "https://www.youtube.com/watch?v=RthMeJEDsls",
    },
  ];

  return (
    <div className="tech">
      <div className="container">
        <div className="my-5">
          <div className="page-title">
            <img
              className="description-logo"
              src="https://i.imgur.com/DAMmUiO.png"
              alt="Genesis Logo"
            ></img>
            <h1 className="font-weight-light">Tech Cards</h1>
            <small>Click on the back of each card to learn more!</small>
            <br />
            <small>
              <em>
                <a
                  href="https://sites.google.com/genesissteam.org/design-it-cards/home"
                  target="_blank"
                  rel="noreferrer"
                  className="alt-cards"
                >
                  Trouble viewing images? Click here!
                </a>
              </em>
            </small>
            <div className="row">
              {techCards.map((card, index) => {
                return (
                  <div className="col-sm-4 mt-3">
                    <TechCard
                      key={index}
                      name={card.name}
                      photo={card.photo}
                      alt={card.alt}
                      description={card.description}
                      link={card.link}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tech;
