import React from "react";
import "./css/Contact.css";

// add photos of educators (or take images out for now)

const Contact = () => {
  let genesisStaff = [
    {
      name: "Daniella Simon",
      photo: "https://i.imgur.com/P2NSHvp.png",
      title: "Director, Educational Programming",
      email: "daniella@genesissteam.org",
    },
  ];

  return (
    <div className="contact">
      <div className="container near-top shadow">
        <div className="row align-items-center my-5">
          <div className="row">
            <img
              className="description-logo"
              src="https://i.imgur.com/DAMmUiO.png"
              alt="Genesis Logo"
            ></img>
            <h1 className="font-weight-light mb-4">Contact</h1>
            {genesisStaff.map((staff, index) => {
              return (
                <div key={index} className="staff-contact col-sm-12 mb-5">
                  <h3>{staff.name}</h3>
                  <img
                    className="staff-photo mb-2"
                    src={`${staff.photo}`}
                    alt=""
                  />
                  <p>
                    <em>{staff.title}</em>
                  </p>

                  <p>
                    <strong>
                      <i className="far fa-envelope"></i>
                    </strong>{" "}
                    <br />
                    {staff.email}
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Contact;
