import React from "react";
import "./css/ReflectionSprint.css";
import Wrapper from "./Wrapper";
import { useState } from "react";

const ReflectionSprint = () => {
  const [show, setShow] = useState(false);
  const value = "final";
  const actualId = "final";

  const toggleReflectionSprint = () => {
    if (show === false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  return (
    <>
      <div className="ReflectionSprint">
        <h3
          className="col-sm-12 text-start sprint-header shadow mb-3 pink-background"
          onClick={toggleReflectionSprint}
        >
          Project Reflection & Culminating Event
        </h3>
        <Wrapper show={show} value={value} actualId={actualId}>
          <h2>Congratulations!</h2>
          <h2>You have completed the Genesis Innovator Program!</h2>
          <p className="text-start">
            Reflection and sharing are an integral part of the Design Thinking
            process. As such, upon ending the program, we highly recommend that
            you elevate and celebrate student work by sharing it with the
            greater community via a culminating event. Additionally, we ask that
            participants fill out their respective Post-Survey (
            <a
              href="https://docs.google.com/forms/d/16a1nIVPkbweMAnGFYQ52pXJAbJDYWA7d9Co1BkkqXV0/edit?usp=sharing"
              alt="Grades: K-3"
              target="_blank"
              rel="noreferrer"
            >
              K-3
            </a>
            /
            <a
              href="https://docs.google.com/forms/d/1OsD_ASAm_UlyLFA8uQwRt6Ptstw4ZvkedIVPZBrwZAA/edit?usp=sharing"
              alt="Grades: 4-5"
              target="_blank"
              rel="noreferrer"
            >
              4-5
            </a>
            /
            <a
              href="https://forms.gle/GMS7FbXWJcXh1pSp7"
              alt="Grades: 6+"
              target="_blank"
              rel="noreferrer"
            >
              6+
            </a>
            /
            <a
              href="https://forms.gle/NzFpYQXEmqiavc3N8"
              alt="Teachers"
              target="_blank"
              rel="noreferrer"
            >
              Teachers
            </a>
            ).
          </p>
          <h4 className="text-start">Project Reflection</h4>
          <p className="text-start">
            Students should download and review their Flipgrid portfolio and
            then use that to complete the Self-evaluation and Exit Survey.
          </p>
          <h4 className="text-start">Culminating Event</h4>
          <p className="text-start">
            The purpose of the culminating event is to elevate student work and
            share with the community. The event can be at the class level or as
            large as a larger community event. Be sure to highlight student
            process and not just student outcomes.{" "}
          </p>
        </Wrapper>
      </div>
    </>
  );
};

export default ReflectionSprint;
