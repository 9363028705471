import React from "react";
import "./css/ProgramBlurb.css";

// Create an engaging and eye catching landing page that communicates the future communities innovator mindset

const ProgramBlurb = () => {
  const hardSkills = [
    "Coding & App Development",
    "Engineering",
    "Digital Design",
    "Augmented & Virtual Reality",
  ];

  const softSkills = [
    "Empathy",
    "Collaboration",
    "Critical Thinking",
    "Leadership",
  ];

  const programOutcomes = [
    "Individualized Student Portfolios",
    "Knowledge of the Engineering & Design Process",
    "Prototype of Future Community Innovation",
    "Hands on work with a variety of technologies and art media",
  ];

  return (
    <div className="program-blurb">
      <div className="container">
        <h3 className="mb-1">Genesis STEAM Challenge:</h3>
        <h1 className="mb-2">Future Communities</h1>
        <h4 className="about-the-program shadow mb-2">About the Program</h4>
        <p className="text-start">
          In the Genesis “Future Communities” program, students are introduced
          to challenges that today's urban engineers face. Students examine
          current real-world problems, like waste management, sustainable
          transportation, and clean energy, and design solutions using a
          combination of art and technology.
          <br />
          <br />
          The Genesis approach engages empathy, art, and technology in all grade
          levels to give students tools that can change their future and current
          communities.
        </p>
        <div className="skills-list">
          <div className="row">
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-6 hard-skills">
                  <h4>Hard Skills</h4>
                  <ul>
                    {hardSkills.map((skill) => {
                      return <li>{skill}</li>;
                    })}
                  </ul>
                </div>

                <div className="col-sm-6 soft-skills">
                  <h4>Soft Skills</h4>
                  <ul>
                    {softSkills.map((skill) => {
                      return <li>{skill}</li>;
                    })}
                  </ul>
                </div>
              </div>

              <div className="col-sm-12 program-outcomes">
                <h4>Program Outcomes</h4>
                <ul>
                  {programOutcomes.map((skill) => {
                    return <li>{skill}</li>;
                  })}
                </ul>
              </div>
            </div>

            <div className="col-sm-6 soft-skills-important">
              <h4>Why are soft skills important?</h4>
              <p className="text-start">
                According to the National Association of Colleges and Employees,
                when participating employers were asked to name the attributes
                they seek in candidates, they gave their highest scores to the
                following three traits:
              </p>
              <ol>
                <li>Written Communication Skills (82%)</li>
                <li>Problem Solving Skills (80.9%)</li>
                <li>Ability to Work in a Team (78.7%)</li>
              </ol>
            </div>
          </div>
        </div>
        <div>
          <h4 className="standards-alignment mt-4">Standards Alignment</h4>
          <p className="text-center">
            <strong>
              The GENESIS Innovator Program is aligned with the following
              Standard Domains:
            </strong>
            <br />
            <br />
            Computational Thinking, CCSS ELA, NGSS, VAPA, and ISTE
            <br />
            <br />
            <a
              href="https://docs.google.com/spreadsheets/d/1YY13czYJBYGqXNbPvbVRFAiXREKc4iEYzyMvgEjmsXo/edit?usp=sharing"
              rel="noreferrer"
              target="_blank"
              className="sprint-resources"
            >
              View the aligned standard domains here
            </a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ProgramBlurb;
