import React from "react";
import ArtCard from "./ArtCard";
import "./css/Art.css";

const Art = () => {
  // art links should be linked directly to the galleries in the art walk 360 space

  let artCards = [
    {
      name: "Sculpture",
      photo: "https://i.imgur.com/DcFhSYl.jpg",
      alt: "Sculpture",
      description:
        "the art of making 2D or 3D forms, especially by carving stone or wood or by casting metal or plaster",
      link: "https://www.thinglink.com/video/1520490639169421314",
    },
    {
      name: "Collage",
      photo: "https://i.imgur.com/g7e01Yu.jpg",
      alt: "Collage",
      description:
        "a piece of art made by sticking various different materials such as photographs, paper, or fabric on to a backing",
      link: "https://www.thinglink.com/video/1520501810211586050",
    },
    {
      name: "Photography",
      photo: "https://i.imgur.com/PahajRS.jpg",
      alt: "Photography",
      description: "the art or practice of taking and processing photographs",
      link: "https://www.thinglink.com/video/1520234323989495811",
    },
    {
      name: "Architecture",
      photo: "https://i.imgur.com/K7gQpdr.jpg",
      alt: "Architecture",
      description:
        "the art or practice of designing and construction buildings",
      link: "https://www.thinglink.com/video/1520520309881438210",
    },
    {
      name: "Textiles",
      photo: "https://i.imgur.com/I7LnQap.jpg",
      alt: "Textiles",
      description:
        "the branch of industry involved in the manufacture of cloth or woven fabric",
      link: "https://www.thinglink.com/video/1520526662477807618",
    },
    {
      name: "Film",
      photo: "https://i.imgur.com/eWdSopj.jpg",
      alt: "Film",
      description:
        "the art or practice of directing, writing, and producing movies or motion pictures",
      link: "https://www.thinglink.com/video/1520962083217211394",
    },
    {
      name: "Found Materials",
      photo: "https://i.imgur.com/8H8jcSN.jpg",
      alt: "Found Material",
      description:
        "art created from items or products that are not normally considered art materials",
      link: "https://www.thinglink.com/video/1520957731949772802",
    },
    {
      name: "Graphic Design",
      photo: "https://i.imgur.com/YNDClns.jpg",
      alt: "Graphic Design",
      description:
        "the art or skill of combining text and pictures in advertisements, magazines, and books",
      link: "https://www.thinglink.com/video/1520512451949363202",
    },
    {
      name: "Light",
      photo: "https://i.imgur.com/DwnaZya.jpg",
      alt: "Light",
      description:
        "an art form in which light is used to create a visual art piece through the use of light, colors, and shadows",
      link: "https://www.thinglink.com/video/1521035976292433922",
    },
    {
      name: "Movement",
      photo: "https://i.imgur.com/akjRQsn.jpg",
      alt: "Movement",
      description: "the art or practice of taking and processing photographs",
      link: "https://www.thinglink.com/video/1521040279891607554",
    },
    {
      name: "Painting & Drawing",
      photo: "https://i.imgur.com/jWwx51O.jpg",
      alt: "Painting and Drawing",
      description:
        "the art or practice of applying paint and/or color to a solid surface while outlining an object or figure",
      link: "https://www.thinglink.com/video/1520970033113595906",
    },
    {
      name: "Sound",
      photo: "https://i.imgur.com/XnlqTSr.jpg",
      alt: "Sound",
      description:
        "sound produced by continuous, regular, and purposeful vibrations -- as opposed to noise",
      link: "https://www.thinglink.com/video/1524552529214439425",
    },
  ];

  return (
    <div className="art mb-5">
      <div class="container">
        <div class="my-5">
          <div class="page-title">
            <img
              className="description-logo"
              src="https://i.imgur.com/DAMmUiO.png"
              alt="Genesis Logo"
            ></img>
            <h1 class="font-weight-light">Art Cards</h1>
            <small>Click on the back of each card to learn more!</small>
            <br />
            <small>
              <em>
                <a
                  href="https://sites.google.com/genesissteam.org/design-it-cards/home"
                  target="_blank"
                  rel="noreferrer"
                  className="alt-cards"
                >
                  Trouble viewing images? Click here!
                </a>
              </em>
            </small>
            <div className="row">
              {artCards.map((card, index) => {
                return (
                  <div className="col-sm-4 mt-3">
                    <ArtCard
                      key={index}
                      name={card.name}
                      photo={card.photo}
                      alt={card.alt}
                      description={card.description}
                      link={card.link}
                    />
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Art;
