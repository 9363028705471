import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import "./SinglePage.css";

export default function SinglePage(props) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  const { pdf } = props;

  return (
    <div className="SinglePage shadow">
      <Document
        file={pdf}
        options={{ workerSrc: "/pdf.worker.js" }}
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <div>
        <p>
          Page {pageNumber || (numPages ? 1 : "--")} of {numPages || "--"}
        </p>

        <button
          className="pdf-button shadow"
          type="button"
          disabled={pageNumber <= 1}
          onClick={previousPage}
        >
          Prev
        </button>
        <button
          className="pdf-button shadow mb-2"
          type="button"
          disabled={pageNumber >= numPages}
          onClick={nextPage}
        >
          Next
        </button>

        <p>
          <a href={props.pdfUrl} target="_blank" rel="noreferrer">
            <i class="fas fa-expand"></i> <i class="fas fa-download"></i>
          </a>
        </p>
      </div>
    </div>
  );
}
