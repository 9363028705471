import React, { useState, Fragment } from "react";
import "./css/Sprints.css";
import Wrapper from "./Wrapper";
import SprintModal from "./SprintModal";
import { Link } from "react-router-dom";
import SinglePagePDFViewer from "./pdf/single-page";
import { useSessionStorage } from "../hooks/useSessionStorage";
import ProgramBlurb from "./ProgramBlurb";
import ReflectionSprint from "./ReflectionSprint";
import bcrypt from "bcryptjs";
import tier1Partners from "./partners/tier1partners";
import tier2Partners from "./partners/tier2partners";
import tier3Partners from "./partners/tier3partners";
import tier1 from "./sprintInfo/tier1sprints";
import tier2 from "./sprintInfo/tier2sprints";
import tier3 from "./sprintInfo/tier3sprints";
import QuickDownloads from "./QuickDownloads";
import { eyeIconOpen } from "./icons/eyes";
import { eyeIconClosed } from "./icons/eyes";

const salt = bcrypt.genSaltSync(10);

const Sprints = () => {
  let [show, setShow] = useState(false); // used for sprint headings
  let [sprintId, setSprintId] = useState("");
  let [open, setOpen] = useState(false); // used for modals
  let [load, setLoad] = useSessionStorage("load", false);
  let [isLoggedIn, setIsLoggedIn] = useSessionStorage("isLoggedIn", false);
  let [code, setCode] = useSessionStorage("code", ""); // used for partner login
  let [showPassword, setShowPassword] = useState("password");
  let [eyeIcon, setEyeIcon] = useState(eyeIconOpen);

  const [sprints, setSprints] = useSessionStorage("sprints", tier1);

  const toggleSprintHeader = (id) => {
    setSprintId(id);

    if (show === false) {
      setShow(true);
    } else {
      setShow(false);
    }
  };

  const togglePassword = (e, inputType) => {
    e.preventDefault();

    if (inputType === "password") {
      setShowPassword("text");
      setEyeIcon(eyeIconClosed);
    } else {
      setShowPassword("password");
      setEyeIcon(eyeIconOpen);
    }
  };

  // modal logic
  const closeModal = () => {
    setOpen(false);
  };

  const openModal = () => {
    setOpen(true);
  };

  if (open === true) {
    document.body.classList.add("active-modal");
  } else {
    document.body.classList.remove("active-modal");
  }

  const handleAccessCode = (event) => {
    setCode(event.target.value);
  };

  const grantAccess = () => {
    setLoad(true);
    setIsLoggedIn(true);
  };

  const denyAccess = () => {
    setLoad(false);
    setIsLoggedIn(false);
  };

  const logOut = (event) => {
    event.preventDefault();

    denyAccess();
    setCode("");
  };

  const checkPassword = (event) => {
    event.preventDefault();

    let partner1 = tier1Partners.find((partner) => partner.accessCode === code);
    let partner2 = tier2Partners.find((partner) => partner.accessCode === code);
    let partner3 = tier3Partners.find((partner) => partner.accessCode === code);

    const hashedCode = bcrypt.hashSync(code, salt);
    console.log(hashedCode);

    if (partner1) {
      // tier 1: sprints 1 - 6
      grantAccess();
    } else if (partner2) {
      // tier 2: sprints 7 - 8
      setSprints(tier1.concat(tier2));
      grantAccess();
    } else if (partner3 || code === "GenesisFC4357") {
      // tier 3: sprints 8 - 10
      setSprints(tier1.concat(tier2, tier3));
      grantAccess();
    } else {
      alert("Oops! Please try your access code again.");
      denyAccess();
    }
  };

  // UI - Login Page
  if (load && isLoggedIn) {
    return (
      <div className="sprints">
        <div className="container">
          <div className="row align-items-center my-5">
            <img
              className="description-logo mb-1"
              src="https://i.imgur.com/DAMmUiO.png"
              alt="Genesis Logo"
            ></img>
            <ProgramBlurb />

            <img
              className="description-logo mb-1"
              src="https://i.imgur.com/DAMmUiO.png"
              alt="Genesis Logo"
            ></img>
            <h3 className="col-sm-12 text-center sprint-header-no-hover shadow mb-3 gray-background">
              Let the innovation begin!
            </h3>

            <a
              href="https://drive.google.com/file/d/1CGNt-If2qdBATtgEqNiR3kdl1DP_dKaY/view?usp=sharing"
              target="_blank"
              rel="noreferrer"
            >
              <img
                className="mb-4"
                src="https://i.imgur.com/jBboIQh.png"
                alt="Genesis Sprint Flow Chart"
              ></img>
            </a>

            <QuickDownloads />

            <p className="col-sm-12 text-center sprint-header-no-hover shadow mb-3 pre-and-post-background">
              Prior to starting your Innovator Program please take time to fill
              out our{" "}
              <a
                href="https://forms.gle/8TSccU92yJPoPD137"
                target="_blank"
                rel="noreferrer"
                className="educator-survey-link"
              >
                Innovator Club "Future Communities" Educator Survey.
              </a>{" "}
            </p>

            {sprints.map((sprint, index) => {
              return (
                <Fragment key={index}>
                  <h3
                    className={sprint.classes}
                    onClick={() => toggleSprintHeader(sprint.id)}
                  >
                    <strong>Sprint {sprint.sprint}</strong> : {sprint.topic}
                  </h3>

                  <Wrapper show={show} value={sprintId} actualId={sprint.id}>
                    <div className="row mt-3 mb-2">
                      <div className="col-sm-6 mb-3">
                        <SinglePagePDFViewer
                          pdf={sprint.pdf}
                          pdfUrl={sprint.sprintUrl}
                        />
                      </div>

                      <div className="col-sm-6">
                        <h5 className="mb-3 sprint-sub-header shadow">
                          Sprint {sprint.sprint}: {sprint.topic}
                        </h5>
                        <p className="text-start description">
                          {sprint.rationale}
                        </p>

                        <div className="col sprint-content">
                          <div className="col-sm-12 text-start mt-3 mb-2">
                            <h5 className="rationale shadow mb-3">
                              Sprint Schedule
                            </h5>
                            <ol>
                              {sprint.schedule.map((item) => {
                                return <li>{item}</li>;
                              })}
                            </ol>
                          </div>

                          <div className="col-sm-12 text-start mt-3 mb-2">
                            <h5 className="learning-outcome shadow mb-3">
                              Learning Outcome
                            </h5>

                            {sprint.learningOutcome}
                          </div>

                          <button
                            href=""
                            title="Sprint Resources"
                            className="sprint-resources shadow"
                            onClick={openModal}
                          >
                            Click To View/Download Materials{" "}
                          </button>

                          <SprintModal
                            open={open}
                            actualId={sprint.id}
                            id={sprintId}
                            close={closeModal}
                          >
                            <div className="modal-header">
                              <h2 className="text-center modal-header">
                                <strong>Sprint {sprint.sprint}</strong>
                              </h2>
                              <button
                                onClick={closeModal}
                                className="close-button ms-auto"
                              >
                                <i className="fas fa-times"></i>
                              </button>
                            </div>

                            <div className="modal-resources">
                              <div className="addtl-resources">
                                <h3 className="materials-header m-3 mb-3">
                                  Materials Needed{" "}
                                  <i class="fas fa-download"></i>
                                </h3>
                                <ul>
                                  {sprint.materials.map((material) => {
                                    if (material.contentUrl === "") {
                                      return <li>{material.title}</li>;
                                    } else {
                                      return (
                                        <li>
                                          <a
                                            href={material.contentUrl}
                                            title={material.title}
                                            target="_blank"
                                            rel="noreferrer"
                                          >
                                            {material.title}
                                          </a>
                                        </li>
                                      );
                                    }
                                  })}
                                </ul>
                              </div>

                              <p>
                                <em>
                                  Need help? Contact us! {"   "}
                                  <Link
                                    className="contact-button"
                                    to="/contact"
                                  >
                                    <i className="far fa-envelope"></i>
                                  </Link>
                                </em>
                              </p>
                            </div>
                          </SprintModal>
                        </div>
                      </div>
                    </div>
                  </Wrapper>
                </Fragment>
              );
            })}

            <ReflectionSprint />
          </div>
          <button className="sprint-resources shadow" onClick={logOut}>
            Log Out
          </button>
        </div>
      </div>
    );
  } else {
    // collect & check user access code
    return (
      <div className="container">
        <div className="sprint-login shadow">
          <img
            className="description-logo mb-1"
            src="https://i.imgur.com/DAMmUiO.png"
            alt="Genesis Logo"
          ></img>
          <h2>Login To Access Sprints</h2>
          <form className="login-form" onSubmit={checkPassword}>
            <div>
              <input
                className="text-center"
                type={showPassword}
                onChange={handleAccessCode}
              ></input>
              <button
                className="m-2"
                onClick={(e) => togglePassword(e, showPassword)}
              >
                {eyeIcon}
              </button>
            </div>
            <small>Access Code (case sensitive)</small>
            <button type="submit">Log In</button>
          </form>
          <p>
            {" "}
            <em>
              Forgot Access Code? <br />
              Email <strong>daniella@genesissteam.org</strong> for a new access
              code.
            </em>
          </p>
        </div>
      </div>
    );
  }
};

export default Sprints;
