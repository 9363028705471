import React from "react";

export default function Wrapper({ show, value, actualId, children }) {
  if (!show) return null;

  if (show === true && value === actualId) {
    return <div>{children}</div>;
  } else {
    return null;
  }
}
