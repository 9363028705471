import React from "react";
import ReactDom from "react-dom";
import "./css/SprintModal.css";

const SprintModal = ({ open, actualId, id, close, children }) => {
  if (!open) return null;

  if (open === true && id === actualId) {
    return ReactDom.createPortal(
      <>
        <div className="overlay" onClick={close} />
        <div className="sprint-modal">{children}</div>
      </>,
      document.getElementById("portal")
    );
  }
};

export default SprintModal;
