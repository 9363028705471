import "./App.css";
import AnimatedBG from "../src/components/AnimatedBG";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import {
  Navigation,
  Footer,
  Home,
  Challenges,
  Tech,
  Art,
  Contact,
  Sprints,
} from "./components";

function App() {
  return (
    <div className="App">
      <AnimatedBG>
        <BrowserRouter>
          <Navigation />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/challenges" element={<Challenges />} />
            <Route path="/tech" element={<Tech />} />
            <Route path="/art" element={<Art />} />
            <Route path="/sprints" element={<Sprints />} />
            <Route path="/contact" element={<Contact />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </AnimatedBG>
    </div>
  );
}

export default App;
