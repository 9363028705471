import React from "react";
import "./css/Home.css";
import ReactPlayer from "react-player";

// Create an engaging and eye catching landing page that communicates the future communities innovator mindset

const Home = () => {
  return (
    <div className="home">
      <div className="container">
        <div className="innovator-info shadow">
          <img
            className="description-logo"
            src="https://i.imgur.com/DAMmUiO.png"
            alt="Genesis Logo"
          ></img>
          <h1 className="mb-3">Welcome to Genesis Innovator</h1>
          <div className="row">
            <ReactPlayer
              className="col-sm-6"
              url="https://i.imgur.com/k678wcw.mp4"
              playing={true}
              loop={true}
              muted={true}
              width="600px"
              height="auto"
            />
            <p className="text-start col-sm-6">
              This innovative and unique program is a{" "}
              <strong className="genesis-blue-text">
                hands-on STEAM learning experience
              </strong>{" "}
              that challenges students to use{" "}
              <strong className="genesis-orange-text">art</strong> and{" "}
              <strong className="genesis-pink-text">technology</strong> to
              address the various challenges communities face today.
              <br />
              <br />
              The program uses a proprietary{" "}
              <strong className="genesis-green-text">
                Design-Make-Share
              </strong>{" "}
              framework to foster creativity, critical thinking, collaboration,
              and communication as students engage with real-world problems in
              their communities.
              <br />
              <br />
              <h4 className="text-center">
                How will you and your students' ideas transform your community?
              </h4>
              <div className="row align-items-center justify-content-center">
                <img
                  className="col-sm-3 lord-icons img-fluid"
                  src="https://i.imgur.com/LOGC8QH.gif"
                  alt="lord-icon"
                  trigger="loop"
                />
                <img
                  className="col-sm-3 lord-icons img-fluid"
                  src="https://i.imgur.com/Rnvp2K2.gif"
                  alt="lord-icon"
                  trigger="loop"
                />
                <img
                  className="col-sm-3 lord-icons img-fluid"
                  src="https://i.imgur.com/jBi0SCl.gif"
                  alt="lord-icon"
                  trigger="loop"
                />
                <img
                  className="col-sm-3 lord-icons img-fluid"
                  src="https://i.imgur.com/DInjwAa.gif"
                  alt="lord-icon"
                  trigger="loop"
                />
              </div>
            </p>
          </div>
        </div>

        <span className="lordicon-credit">
          Animated Icons by{" "}
          <a
            href="https://lordicon.com/"
            alt="Visit Lordicon"
            target="_blank"
            rel="noreferrer"
          >
            Lordicon
          </a>
        </span>
      </div>
    </div>
  );
};

export default Home;
