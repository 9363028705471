import sprintIndex from "../pdf/sprintIndex";

// sprints for tier3 partners
// sprints 9 - 10
const tier3 = [
  {
    id: "sprint9",
    sprint: "9",
    topic: "Branding & Storytelling",
    rationale:
      "In this sprint students will spend some time developing the branding look for their prototype. Students will use Canva, a free easy-to-use graphic design website, to create a logo and infographic for their prototype. They will share this as part of the culminating event.",
    schedule: [
      "Intro and Goals (5 mins)",
      "Develop Branding (30 mins)",
      "Portfolio: By Design (10 mins)",
    ],
    learningOutcome:
      "Create a logo for their solution using Canva; Create an infographic for their solution using Canva; Collaborative Design",
    materials: [
      {
        title: "Canva Infographic Video",
        contentUrl:
          "https://www.youtube.com/watch?app=desktop&v=Yvo-mHq1ojU&feature=youtu.be",
      },
      {
        title: "Logo Design Video",
        contentUrl:
          "https://www.youtube.com/watch?app=desktop&v=x6Ubl4ETdCg&feature=youtu.be",
      },
      {
        title: "1 Device per student",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1btDDHFiobHxY_nQRutB2YBAZkYaAfLt2/view?usp=sharing",
    pdf: sprintIndex.sprintPDF9,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 blue-background",
  },
  {
    id: "sprint10",
    sprint: "10",
    topic: "Developing the Pitch",
    rationale:
      "In this sprint students will focus on storytelling, public speaking and presentation as they plan, write and produce a one minute pitch that they will also present at the culminating event. ",
    schedule: [
      "Introduction and Goals (5 mins)",
      "Pitch Improv (15 mins)",
      "Develop the Pitch (20 mins)",
      "Portfolio: Pitch Video (5 mins)",
    ],
    learningOutcome:
      "Practice public speaking; Engage in persuasive writing and speaking",
    materials: [
      {
        title: "1 device per team",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1bE4BIONKOubSu8my72HjRsjVm3RyvW1j/view?usp=sharing",
    pdf: sprintIndex.sprintPDF10,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 orange-background",
  },
];

export default tier3;
