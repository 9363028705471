// import { storage } from "../firebase";
// import { ref } from "firebase/storage";
import sprint1 from "./sprint1.pdf";
import sprint2 from "./sprint2.pdf";
import sprint3 from "./sprint3.pdf";
import sprint4 from "./sprint4.pdf";
import sprint5 from "./sprint5.pdf";
import sprint6 from "./sprint6.pdf";
import sprint7 from "./sprint7.pdf";
import sprint8 from "./sprint8.pdf";
import sprint9 from "./sprint9.pdf";
import sprint10 from "./sprint10.pdf";

// const sprintIndex = {
//   sprintPDF1: ref(storage, "sprints/sprint1.pdf"),
//   sprintPDF2: ref(storage, "sprints/sprint2.pdf"),
//   sprintPDF3: ref(storage, "sprints/sprint3.pdf"),
//   sprintPDF4: ref(storage, "sprints/sprint4.pdf"),
//   sprintPDF5: ref(storage, "sprints/sprint5.pdf"),
//   sprintPDF6: ref(storage, "sprints/sprint6.pdf"),
//   sprintPDF7: ref(storage, "sprints/sprint7.pdf"),
//   sprintPDF8: ref(storage, "sprints/sprint8.pdf"),
//   sprintPDF9: ref(storage, "sprints/sprint9.pdf"),
//   sprintPDF10: ref(storage, "sprints/sprint10.pdf"),
// };

const sprintIndex = {
  sprintPDF1: sprint1,
  sprintPDF2: sprint2,
  sprintPDF3: sprint3,
  sprintPDF4: sprint4,
  sprintPDF5: sprint5,
  sprintPDF6: sprint6,
  sprintPDF7: sprint7,
  sprintPDF8: sprint8,
  sprintPDF9: sprint9,
  sprintPDF10: sprint10,
};

export default sprintIndex;
