const tier3Partners = [
  // has access to sprints 1 - 10
  {
    organization: "Euclid Elementary",
    accessCode: "Innovator101",
  },
  {
    organization: "Santa Monica Boulevard Community Charter School",
    accessCode: "Smbcc1022",
  },
  {
    organization: "Dolores Huerta Elementary School",
    accessCode: "Dhe260",
  },
  {
    organization: "Palisades Charter School",
    accessCode: "Pali800",
  },
  {
    organization: "Null",
    accessCode: "Innovator111",
  },
  {
    organization: "Null",
    accessCode: "Innovator112",
  },
  {
    organization: "Null",
    accessCode: "Innovator113",
  },
  {
    organization: "Null",
    accessCode: "Innovator114",
  },
  {
    organization: "Null",
    accessCode: "Innovator115",
  },
  {
    organization: "Null",
    accessCode: "Innovator116",
  },
  {
    organization: "Null",
    accessCode: "Innovator117",
  },
  {
    organization: "Null",
    accessCode: "Innovator118",
  },
  {
    organization: "Null",
    accessCode: "Innovator119",
  },
  {
    organization: "Null",
    accessCode: "Innovator120",
  },
  {
    organization: "Null",
    accessCode: "Innovator121",
  },
  {
    organization: "Null",
    accessCode: "Innovator122",
  },
  {
    organization: "Null",
    accessCode: "Innovator123",
  },
  {
    organization: "Null",
    accessCode: "Innovator124",
  },
  {
    organization: "Null",
    accessCode: "Innovator125",
  },
  {
    organization: "Null",
    accessCode: "Innovator126",
  },
  {
    organization: "Null",
    accessCode: "Innovator127",
  },
  {
    organization: "Null",
    accessCode: "Innovator128",
  },
  {
    organization: "Null",
    accessCode: "Innovator129",
  },
  {
    organization: "Null",
    accessCode: "Innovator130",
  },
];

export default tier3Partners;
