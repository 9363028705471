import sprintIndex from "../pdf/sprintIndex";

// sprints for tier1 partners
// sprints 1 - 4
const tier1 = [
  {
    id: "sprint1",
    sprint: "1",
    topic: "Building Empathy",
    rationale:
      "In this sprint students will learn why empathy is a driving force behind effective design by participating in a performative and engaging empathy exercise. Students will review a scenario in which someone has witnessed another person littering. Students are then tasked with pausing their judgement, putting themselves in that person's shoes, and rewriting the scene from the perspective of the person who littered in an attempt to understand the 'why' behind their actions. With this new perspective, students will then be able to imagine solutions that can prevent the incident from happening again. ",
    schedule: [
      "Pre-Survey (5 mins)",
      "Introduction and Goals (5 mins)",
      "Empathy Scenario (25 mins)",
      "Portfolio: Perspective (10 mins)",
    ],
    learningOutcome:
      "Develop an understanding of Genesis and the iLab experience; Practice engaging in empathy; Navigate the iLabs website",
    materials: [
      {
        title: "Genesis Pre-Surveys (K-3) - PDF",
        contentUrl:
          "https://drive.google.com/file/d/1oAJ812NkYELj7jKGrWvVRzXbBXr0CYQ2/view?usp=sharing",
      },
      {
        title: "Genesis Pre-Survey (K-3) - Digital",
        contentUrl:
          "https://docs.google.com/forms/d/e/1FAIpQLSfviTw-zYmJNzm7TJTJlnrvUge7UuwZ9Oc_bP7JfCo44vw8Yg/viewform?usp=sf_link",
      },
      {
        title: "Genesis Pre-Survey (4-5) - PDF",
        contentUrl:
          "https://drive.google.com/file/d/1MsiM8cr6AE9xDrgJCi5YIKXdtdinJ1yR/view?usp=sharing",
      },
      {
        title: "Genesis Pre-Survey (4-5) - Digital",
        contentUrl:
          "https://docs.google.com/forms/d/e/1FAIpQLSew6Ev8-NDfNlm_1_pWR9GSrVE8E8AQFSVM-DbRlLAKglPvsg/viewform?usp=sf_link",
      },
      {
        title: "Genesis Pre-Survey (6-12) - Digital",
        contentUrl: "https://forms.gle/qsRVYGiGLvDrbfQQA",
      },
      {
        title: "Design-It Game Cards",
        contentUrl: "",
      },
      {
        title: "1 device per student",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1YGLV3lvQ2syGr1DhFquGLp4AAJ-ASqIp/view?usp=sharing",
    pdf: sprintIndex.sprintPDF1,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 blue-background",
  },
  {
    id: "sprint2",
    sprint: "2",
    topic: "Art Walk",
    rationale:
      "In this sprint students will identify and learn more about the 12 art mediums that will be activated in this program. Students will access our Genesis 360 galleries to see contemporary examples of each medium, learn more about current artists, and practice the art form using creative digital tools. Students will then explore and reflect on how they can use art as a method to communicate and effect change. ",
    schedule: [
      "Intro and Goals (5 mins)",
      "Art Walk (25 mins)",
      "Art in Macro (10 mins)",
      "Portfolio: Art Talk (5 mins)",
    ],
    learningOutcome:
      "Identify major art mediums and movements; Practice using a variety of digital creation tools; Brainstorming and ideation",

    materials: [
      {
        title: "Art Walk - Virtual",
        contentUrl: "https://www.thinglink.com/video/1534678511439052802",
      },
      {
        title: "Design-It Art Game Cards",
        contentUrl: "",
      },
      {
        title: "1 device per student",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/11gTqoS34QpIMRQIrwd9Lp4I0n7ZsX5qZ/view?usp=sharing",
    pdf: sprintIndex.sprintPDF2,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 orange-background",
  },
  {
    id: "sprint3",
    sprint: "3",
    topic: "Tech Walk",
    rationale:
      "In this sprint students will identify and learn more about the 12 tech mediums that will be activated in this program. Students will access the Genesis 360 galleries to learn through tutorials, simulators, and virtual experiences, in order to better understand the possible uses of each technology. Students will then explore and reflect on how technology can be used to effect change by adding interactivity, fun, information gathering, and more to new and existing systems. ",
    schedule: [
      "Intro and Goals (5 mins)",
      "Tech Walk (25 mins)",
      "Build a Better Waste Bin (25 mins)",
      "Portfolio: Tech Talk (10 mins)",
    ],
    learningOutcome:
      "Identify major tech tools and their function; Experience using digital tutorials and simluator tools",
    materials: [
      {
        title: "Tech Walk - Virtual",
        contentUrl: "https://www.thinglink.com/video/1534679085660241922",
      },
      {
        title: "Design-It Tech Game Cards",
        contentUrl: "",
      },
      {
        title: "1 Device per student",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1QWxljd8jDwlhlgTForEsyDT8S8MCR0YO/view?usp=sharing",
    pdf: sprintIndex.sprintPDF3,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 pink-background",
  },
  {
    id: "sprint4",
    sprint: "4",
    topic: "Design-It!",
    rationale:
      "Now that you and your class are well-versed in the challenges you have chosen, you are ready to play Design-It! This game is meant to help students develop design thinking to come up with solutions to the challenges you and your class chose. ",
    schedule: [
      "Introduction and Goals (5 mins)",
      "Design-It Game (30 mins)",
      "Portfolio: Ideation (10 mins)",
    ],
    learningOutcome:
      "Work through Design Thinking process; Collaboration and compromise",
    materials: [
      {
        title: "1 Design-It Game per group",
        contentUrl: "",
      },
      {
        title: "1 device per group",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1QyDJQiweS5pcTdewBGhZ85HjyT_RxyVX/view?usp=sharing",
    pdf: sprintIndex.sprintPDF4,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 green-background",
  },
  {
    id: "sprint5",
    sprint: "5",
    topic: "Design Roadmap",
    rationale:
      "The purpose of this sprint is for student teams to develop a clear vision for their project and a specific plan to work towards creating their prototype. Students will identify tasks for each member, and determine what they will work on when visiting the lab.",
    schedule: ["Intro and Goals (5 mins)", "Design Roadmap (30 mins)"],
    learningOutcome: "Planning; Collaboration and Compromise",
    materials: [
      {
        title: "1 Device per team",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1fLxIweUoWRPx6Xsrzb2d6Kf-404dtT9g/view?usp=sharing",
    pdf: sprintIndex.sprintPDF5,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 blue-background",
  },
  {
    id: "sprint6",
    sprint: "6",
    topic: "In-Class Lab Activity",
    rationale:
      "The purpose of the lab activity is for students to create a prototype of the creation they have been developing through the Design-It! game and the Design Roadmap. Students will have classroom time and space to bring their designs and drawings to life!",
    schedule: [
      "Introductino and Goals (5 mins)",
      "In Class Prototyping (35 mins)",
      "Portfolio: Progress Report (5 mins)",
    ],
    learningOutcome:
      "Team building; Empathy building; Develop understanding of the working collaboratively with a team to draft and complete a prototype",
    materials: [
      {
        title: "In-Class Lab Activity",
        contentUrl:
          "https://drive.google.com/file/d/1PZrsOcMlwU9jMVqRlvnmKI-CsAwDSQvX/view?usp=sharing",
      },
      {
        title: "Student Design Roadmap from previous sprint",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1PZrsOcMlwU9jMVqRlvnmKI-CsAwDSQvX/view?usp=sharing",
    pdf: sprintIndex.sprintPDF6,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 orange-background",
  },
];

export default tier1;
