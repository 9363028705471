import React from "react";
import { Link, useNavigate } from "react-router-dom";

import "./css/Navigation.css";

function Navigation() {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
  };

  const goContact = () => {
    navigate("/contact");
  };

  const goChallenges = () => {
    navigate("/challenges");
  };

  const goArt = () => {
    navigate("/art");
  };

  const goTech = () => {
    navigate("/tech");
  };

  const goSprints = () => {
    navigate("/sprints");
  };

  return (
    <div className="Navigation">
      <nav className="navbar navbar-expand navbar-dark bg-dark fixed-top">
        <div className="container">
          <Link className="navbar-brand" to="/" onClick={goHome}>
            <img
              className="img-fluid rounded mb-2 mb-lg-0 navbar-logo-genesis"
              src="https://i.imgur.com/DN5yk3b.png"
              alt=""
            />

            <img
              className="img-fluid rounded mb-2 mb-lg-0 navbar-logo-ilab"
              src="https://i.imgur.com/dnjUQns.png"
              alt=""
            />
          </Link>

          <div>
            <ul className="navbar-nav ml-auto">
              <li
                // className={`nav-item  ${
                //   props.location.pathname === "/" ? "active" : ""
                // }`}
                className="nav-item"
              >
                <Link className="nav-link home-link" to="/" onClick={goHome}>
                  <i class="fas fa-home"></i>
                </Link>
              </li>
              /
              <li
                // className={`nav-item  ${
                //   props.location.pathname === "/contact" ? "active" : ""
                // }`}
                className="nav-item"
              >
                <Link
                  className="nav-link contact-link"
                  to="/contact"
                  onClick={goContact}
                >
                  <i className="far fa-envelope"></i>
                </Link>
              </li>
              /
              <li
                // className={`nav-item  ${
                //   props.location.pathname === "/challenges"
                //     ? "active-challenges"
                //     : ""
                // }`}
                className="nav-item"
              >
                <Link
                  className="nav-link challenges-link"
                  to="/challenges"
                  onClick={goChallenges}
                >
                  Challenges
                </Link>
              </li>
              <li
                // className={`nav-item  ${
                //   props.location.pathname === "/art" ? "active-art" : ""
                // }`}
                className="nav-item"
              >
                <Link className="nav-link art-link" to="/art" onClick={goArt}>
                  Art
                </Link>
              </li>
              <li
                // className={`nav-item  ${
                //   props.location.pathname === "/tech" ? "active-tech" : ""
                // }`}
                className="nav-item"
              >
                <Link
                  className="nav-link tech-link"
                  to="/tech"
                  onClick={goTech}
                >
                  Tech
                </Link>
              </li>
              <li
                // className={`nav-item  ${
                //   props.location.pathname === "/sprints" ? "active-home" : ""
                // }`}
                className="nav-item"
              >
                <Link
                  className="nav-link home-link"
                  to="/sprints"
                  onClick={goSprints}
                >
                  Sprints
                </Link>
              </li>
              <li>
                <a
                  className="nav-link contact-link student-portal-link"
                  href="https://sites.google.com/genesissteam.org/futurecommunitiesstudentportal/home"
                  target="_blank"
                  rel="noreferrer"
                >
                  Student Portal
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navigation;
