import React from "react";
import "./css/ArtCard.css";

const ArtCard = (props) => {
  return (
    <div className="ArtCard">
      <div className="content">
        <div className="front">
          <img className="img-fluid" src={`${props.photo}`} alt={props.alt} />
        </div>

        <div className="back">
          <h3 className="pt-4">{props.name}</h3>

          <p className="text-start">{props.description}</p>
          <a href={props.link} target="_blank" rel="noreferrer">
            <button className="learn-more-button">Learn more!</button>
          </a>
        </div>
      </div>
    </div>
  );
};

export default ArtCard;
