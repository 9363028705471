// array of tiered partners
const tier1Partners = [
  // has access to sprints 1 - 6
  {
    organization: "St. Mary's Academy",
    accessCode: "Sma701",
  },
  {
    organization: "Young Oak Kim",
    accessCode: "Yoka615",
  },
  {
    organization: "Jordan High School",
    accessCode: "Jhs6500",
  },
  {
    organization: "93rd Elementary",
    accessCode: "93elem330",
  },
  {
    organization: "Logan Elementary",
    accessCode: "Innovator100",
  },
  {
    organization: "Vine Street",
    accessCode: "Innovator102",
  },
  {
    organization: "Null",
    accessCode: "Innovator103",
  },
  {
    organization: "Null",
    accessCode: "Innovator104",
  },
  {
    organization: "Null",
    accessCode: "Innovator105",
  },
  {
    organization: "Null",
    accessCode: "Innovator106",
  },
  {
    organization: "Null",
    accessCode: "Innovator107",
  },
  {
    organization: "Null",
    accessCode: "Innovator108",
  },
  {
    organization: "Null",
    accessCode: "Innovator109",
  },
  {
    organization: "Null",
    accessCode: "Innovator110",
  },
];

export default tier1Partners;
