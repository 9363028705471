import sprintIndex from "../pdf/sprintIndex";

// sprints for tier2 partners
// sprints 6 - 8
const tier2 = [
  {
    id: "sprint7",
    sprint: "7",
    topic: "Testing & Feedback",
    rationale:
      "InI this sprint, students will design a test of their prototype's functionality. Students will test their prototypes, give and receive feedback, and then iterate on their design based on the feedback.",
    schedule: [
      "Introduction and Goals (5 mins)",
      "Prepare for Testing and Feedback (10 mins)",
      "Feedback Meetings (25 mins)",
      "Portfolio: Room for Improvement (5 mins)",
    ],
    learningOutcome:
      "Test design and data collection; Presentation skills; Giving and recieving feedback",
    materials: [
      {
        title: "PBS SciGirls | Pedal Power video",
        contentUrl:
          "https://ca.pbslearningmedia.org/resource/9d62ea42-64e5-4be8-95bb-b574b311b883/pedal-power-05-test-and-redesign/",
      },
      {
        title: "1 device per student",
        contentUrl: "",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/10t2MNxqQ9jPFx9jlAilLkBJr1l5Vv4b5/view?usp=sharing",
    pdf: sprintIndex.sprintPDF7,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 pink-background",
  },
  {
    id: "sprint8",
    sprint: "8",
    topic: "Visual Prototype",
    rationale:
      "In this sprint students will make the functional changes they planned in the previous sprint and then focus on the visual aspect of their prototype.  Students should spend their time making their prototype look as much like a finalized version as possible. This will be last session to build out the prototype, so students should focus all of their efforts on getting it to a presentation level.",
    schedule: [
      "Introduction and Goals (5 mins)",
      "Prototyping (25 mins)",
      "Portfolio: Group Photo (10 mins)",
    ],
    learningOutcome: "Visual prototyping; collaboration",
    materials: [
      {
        title: "A variety of craft supplies and tools",
        contentUrl: "",
      },
      {
        title: "BMW Concept car video",
        contentUrl: "https://www.youtube.com/watch?app=desktop&v=Q_3yk3q_tFc",
      },
    ],
    sprintUrl:
      "https://drive.google.com/file/d/1ZxiJ8UEYlHu9H7w2eIZRLtaaOpUa_OQS/view?usp=sharing",
    pdf: sprintIndex.sprintPDF8,
    classes: "col-sm-12 text-start sprint-header shadow mb-3 green-background",
  },
];

export default tier2;
