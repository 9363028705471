import React from "react";

const QuickDownloads = () => {
  return (
    <>
      {" "}
      <p className="col-sm-12 text-center sprint-header-no-hover shadow mb-3 pre-and-post-background">
        <strong>
          <u>Quick Download Links:</u>
        </strong>
        <br />
        <br />
        <a
          href="https://drive.google.com/file/d/1Gztrm8LAwqYf6UnPdWzft-eQrO6cubs4/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          Sprints 1 - 10
        </a>
        <br />
        <a
          href="https://drive.google.com/file/d/1zBUYFHP3wUNm1F7EsQgTJGdquG09CO3Y/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          Sprints 1 - 10 Overview Checklist
        </a>
        <br />
        <a
          href="https://drive.google.com/file/d/1Nl00UJIoVWNQDiiRPuNELheyyZ4QmQfo/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          Updated Sprint 7 (updated: 04/27/2022)
        </a>
        <br />
        <a
          href="https://drive.google.com/file/d/1t4L8TH5g48HuTBPdWzkgXZrntfaLRMy5/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          Focus Wall Sprint Headers (Visual Process)
        </a>
        <br />
        <a
          href="https://drive.google.com/file/d/12lNot4EO25VT93h9UVyYEp4EU63mCKtz/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          Elements of Contemporary Art Vocab Word Wall Cards
        </a>
        <br />
        <a
          href="https://drive.google.com/file/d/11ZNolfEbgaxQYzEWh_YmvxnmHJbXQILC/view?usp=sharing"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          Contemporary Art Checklist
        </a>
        <br />
        <a
          href="https://sites.google.com/genesissteam.org/futurecommunitiesstudentportal/home"
          target="_blank"
          rel="noreferrer"
          className="program-assessment-link"
        >
          View Student Portal
        </a>
      </p>
    </>
  );
};

export default QuickDownloads;
