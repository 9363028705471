import React from "react";
import "./css/Challenges.css";
import ChallengeCard from "./ChallengeCard";

const Challenges = () => {
  let challenges = [
    {
      name: "Accessiblity",
      photoFront: "https://i.imgur.com/ZmKofsq.jpg",
      photoBack: "https://i.imgur.com/RMFiyjo.jpg",
      QRLink: "https://youtu.be/YBxijyu16P0",
    },
    {
      name: "Activity and Recreation",
      photoFront: "https://i.imgur.com/LOOkn5e.jpg",
      photoBack: "https://i.imgur.com/I67Qx3c.jpg",
      QRLink: "https://youtu.be/EpMV5mgO6Ys",
    },
    {
      name: "Animals",
      photoFront: "https://i.imgur.com/icTk6hF.jpg",
      photoBack: "https://i.imgur.com/egjIspI.jpg",
      QRLink: "https://youtu.be/8uQ18W-8XMM",
    },
    {
      name: "Business",
      photoFront: "https://i.imgur.com/JYCNMuj.jpg",
      photoBack: "https://i.imgur.com/OhT193F.jpg",
      QRLink: "https://youtu.be/kgesRggdCF8",
    },
    {
      name: "Energy",
      photoFront: "https://i.imgur.com/moIvqtG.jpg",
      photoBack: "https://i.imgur.com/04qdFca.jpg",
      QRLink: "https://youtu.be/5lCfgUq3Puw",
    },
    {
      name: "Food and Nutrition",
      photoFront: "https://i.imgur.com/qxwSIdI.jpg",
      photoBack: "https://i.imgur.com/CNMa592.jpg",
      QRLink: "https://youtu.be/ablHnJovxQE",
    },
    {
      name: "Green Spaces",
      photoFront: "https://i.imgur.com/BgZZnmk.jpg",
      photoBack: "https://i.imgur.com/IsI4WgB.jpg",
      QRLink: "https://youtu.be/q5Hgl4JDeq4",
    },
    {
      name: "Safety",
      photoFront: "https://i.imgur.com/h3JG1sz.jpg",
      photoBack: "https://i.imgur.com/aUQD4O3.jpg",
      QRLink: "https://youtu.be/m2Mj_F1smbg",
    },
    {
      name: "Schools and Libraries",
      photoFront: "https://i.imgur.com/XelC5lg.jpg",
      photoBack: "https://i.imgur.com/dIoXIoj.jpg",
      QRLink: "https://youtu.be/W5uGdh5Bq9Q",
    },
    {
      name: "Transportation",
      photoFront: "https://i.imgur.com/m58aept.jpg",
      photoBack: "https://i.imgur.com/cxRJOA7.jpg",
      QRLink: "https://youtu.be/VA7JbRJDFZQ",
    },
    {
      name: "Waste",
      photoFront: "https://i.imgur.com/V6wMq6v.jpg",
      photoBack: "https://i.imgur.com/z1rXcPa.jpg",
      QRLink: "https://youtu.be/JlIhN5jzOQw",
    },
    {
      name: "Water",
      photoFront: "https://i.imgur.com/PQFTUxZ.jpg",
      photoBack: "https://i.imgur.com/PHw4HhO.jpg",
      QRLink: "https://youtu.be/uIN9Ydfuglc",
    },
  ];

  return (
    <div className="Challenges">
      <div className="container near-top">
        <div className="my-5">
          <div className="page-title mb-4">
            <img
              className="description-logo"
              src="https://i.imgur.com/DAMmUiO.png"
              alt="Genesis Logo"
            ></img>
            <h1 className="font-weight-light">Challenges</h1>
            <p className="mt-2">
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/M-iJM02m_Hg"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
              ></iframe>
            </p>
            <small>
              Click on the back of each card below to watch a YouTube video
              about the challenge!{" "}
            </small>
            <br />
            <small>
              <em>
                <a
                  href="https://sites.google.com/genesissteam.org/design-it-cards/home"
                  target="_blank"
                  rel="noreferrer"
                  className="alt-cards"
                >
                  Trouble viewing images? Click here!
                </a>
              </em>
            </small>
          </div>
          <div className="row">
            {challenges.map((challenge, index) => {
              return (
                <div className="col-sm-4">
                  <ChallengeCard
                    key={index}
                    name={challenge.name}
                    photo={challenge.photo}
                    alt={challenge.name}
                    front={challenge.photoFront}
                    back={challenge.photoBack}
                    link={challenge.QRLink}
                  />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Challenges;
