import React from "react";
import "./css/ChallengeCard.css";

const ChallengeCard = (props) => {
  return (
    <div className="ChallengeCard">
      <div className="content">
        <div className="front">
          <img className="mb-3" src={`${props.front}`} alt={`${props.name}`} />
        </div>

        <div className="back">
          <a href={props.link} target="_blank" rel="noreferrer">
            <img
              className="mb-3 parent-img-responsive"
              src={`${props.back}`}
              alt={`${props.name}`}
            />
            <img
              className="child-image"
              src="https://i.imgur.com/hMElHYt.png"
              alt="Visit YouTube"
            />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ChallengeCard;
